import { ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import { FilterModel, FilterOption, IErrorModel, IFilterModel, IPagedResultModel } from '@/common/types/filterModel';
import { httpClient } from './httpClient';
import { IAssetTransferOfRightRecord, IOperationResultModel } from './models/asset';
import { AdIDModel, AddIdModel, BusinessTaxInfoModel, ICompanySignatoryMutate, ICreateCompany, ICreateCompanyResponse, IDeleteADID, IDeleteAddress, IDeleteDocumentValue, IDeletePackagingVendor, IDeletePayrollVendor, IDeleteProduct, IDeleteStaff, IDocumentValue, IDocumentViewModel, IHierarchyData, IPayrollVendorAddPayload, IPayrollVendorRecord, ISignatoryVendorRecord, ISocialMediaAddModel, IUpdateCompany, StaffViewModel } from './models/company';
import { BrandModel, ISignatoryRecord } from '@/modules/company/shared/models/company';
import { IClientAdvertiser, IClientAdvertiserModel } from '@/modules/company/components/companyInfo/components/tabs/clientAdvertiser/shared/models/clientAdvertiserModel';
import { IPersonRecord } from './models/person';
import { AxiosResponse } from 'axios';

export const createCompany = (payload: ICreateCompany) => {
	return httpClient.post<ICreateCompanyResponse>('/Company', payload);
};

export const getCompanyDetailsById = (id: any) => {
	return httpClient.get<ICompanyRecord>(`/Company/${id}`);
};

export const getCompanyForBasicDetail = (id: any) => {
	return httpClient.get<ICompanyRecord>(`/Company/BasicDetail/${id}`);
};

export const getCompanyList = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Company/GetBy', filterModel);
};

export const getByCompaniesForBasicDetail = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Company/GetByForBasicDetail', filterModel);
};

export const getLoanoutCompanyList = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Company/GetLoanoutCompany', filterModel);
};

export const checkCompany = (companyName:string) => {
	return httpClient.get(`/Company/CheckCompany/${companyName}`);
};

export const saveAddresses = (params: any) => {
	return httpClient.post(`/${params.apiLabel}/Address/Save`, params);
};

export const saveProducts = (params: any) => {
	return httpClient.post('/Company/Product/Save', params);
};

export const addDivisionForCompany = (params: any) => {
	return httpClient.post('/Company/Division/Add', params);
};

export const getAllDivisions = (filterModel: any, companyId: any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Company/Divisions/${companyId}`, filterModel);
};

export const addBrandForCompany = (params: any) => {
	return httpClient.post<IOperationResultModel>('/Company/Brand/Add', params);
};

export const savePackagingVendors = (payload: any) => {
	return httpClient.post(`/${payload.apiLabel}/Product/PackagingVendor/Save`, payload);
};

export const getAllProducts = (filterModel: any, companyId: any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Company/Products/${companyId}`, filterModel);
};

export const getAllAddressByCompany = (filterModel: IFilterModel, companyId: string) => {
	return httpClient.post<IPagedResultModel<ICompanyAddress>>(`/Company/Address/GetByCompany/${companyId}`, filterModel);
};

export const getAllStaff = (id: any) => {
	return httpClient.post(`/Company/Staff/GetAll/${id}`, {
		pageNumber: 0,
		sortField: 'Id',
		pageSize: 50,
		sortDescending: true,
		filters: [
		]
	});
};

export const getAllStaffOfCompany = (companyId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>(`/Company/Staff/GetAll/${companyId}`, filters);
};

export const saveStaff = (formModel:any) => {
	return httpClient.post('/Company/AddStaff', formModel);
};

export const saveContacts = (formModel:any) => {
	return httpClient.post('Company/Contact/Save', formModel);
};

export const deleteContact = (params: any) => {
	return httpClient.delete('Company/Contact/Delete', params);
};

export const updateAddressById = (addressModel: any) => {
	return httpClient.put('/Company/Address/Update', addressModel);
};

// export const getStaffList = (filterModel: IFilterModel) => {
// 	return httpClient.post<IPagedResultModel<StaffViewModel>>('/Staff/GetAll', filterModel);
// };

export const updateStaff = (filterModel:any) => {
	return httpClient.put('/Staff/UpdateStaff', filterModel);
};

export const getStaffById = (id:any) => {
	return httpClient.get('/Staff/GetStaffById/'+ id);
};

export const getStaffListByCompany = (filterModel: IFilterModel,id:any) => {
	return httpClient.post<IPagedResultModel<StaffViewModel>>(`/Company/Staff/GetAll/${id}`, filterModel);
};

export const UpdateStaff = (formModel:any) => {
	return httpClient.put('/Company/UpdateStaff', formModel);
};

export const createDocument = (payload: IDocumentValue) => {
	return httpClient.post('/Document/AddDocument', payload);
};

export const addCompanyHierarchy = (payload: IHierarchyData) => {
	return httpClient.post('/Company/CompanyHierarchy/Add', payload);
};

export const getDocumentList = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<IDocumentViewModel>>(`/Document/GetAll/${id}`, filterModel);
};

export const getAdvertID = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<AdIDModel>(`/ADID/GetAll/${id}`, filterModel);
};

export const addAdvertID = (payload: AddIdModel) => {
	return httpClient.post('/ADID/AddADID', payload);
};

export const updateAdvertID = (payload: any, id: string) => {
	return httpClient.put(`/ADID/${id}`, payload);
};

export const deleteDocument = (payload: IDeleteDocumentValue) => {
	return httpClient.delete('Document', payload);
};

export const deleteAddress = (payload: IDeleteAddress) => {
	return httpClient.delete('Company/Address/Delete', payload);
};

export const updateCompany = (payload: IUpdateCompany) => {
	return httpClient.put<IErrorModel>('/Company', payload);
};

export const createPayrollVendor = (payload: IPayrollVendorAddPayload) => {
	return httpClient.post('/PayrollVendor', payload);
};

export const deleteADID = (payload: IDeleteADID) => {
	return httpClient.delete('ADID/Delete', payload);
};

export const deleteProduct = (payload: IDeleteProduct) => {
	return httpClient.delete('Company/Product/Delete', payload);
};

export const deletePackagingVendor = (payload: IDeletePackagingVendor) => {
	return httpClient.delete('Company/PackagingVendor/Delete', payload);
};

export const getCompanyBasicDetails = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Company/GetBasicDetails', filterModel);
};

export const getAdvertiserWithAddresses = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Company/GetAddressesForCompany', filterModel);
};

export const getAllCompanies = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Company/Companies', filterModel);
};

export const getBlobURL = (payload : any) => {
	return httpClient.get<string>(`Blob/Nabu/GetBlobUrl/${payload}`);
};

export const getPayrollvendors = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IPayrollVendorRecord>>('/PayrollVendor/GetBy', filterModel);
};

export const deletePayrollVendor = (payload: IDeletePayrollVendor) => {
	return httpClient.delete('/PayrollVendor/Delete', payload);
};

export const deleteMinorPermit = (payload: any) => {
	return httpClient.delete('/Company/MinorPermit/Delete', payload);
};

export const deleteInsurance = (payload: any) => {
	return httpClient.delete('/Company/Insurance/Delete', payload);
};

export const deleteTransferOfRights = (payload: any) => {
	return httpClient.delete('/Company/TransferOfRights/Delete', payload);
};

export const deleteStaff = (payload: any) => {
	return httpClient.delete('/Company/Staff/Delete', payload);
};

export const deleteCompany = (payload: IDeleteStaff) => {
	return httpClient.delete('/Company/Delete', payload);
};

export const getPeopleBasicDetails = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<any>>('/Member/PersonDetails', filterModel);
};

export const getPeopleBasicDetailsById = (personId:any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Member/GetPersonBasicDetailById/${personId}`);
};

export const addMember = (formdata:any) => {
	return httpClient.post('/Member/AddMember',formdata);
};

export const getAllMember = (filterModel: IFilterModel,companyId:any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Member/GetAll/${companyId}`,filterModel);
};

export const deleteMember = (params:any) => {
	return httpClient.delete(`/Member/DeleteMember/${params.companyId}/${params.personId}`);
};

export const addNewEmail = (formdata:any,personId:any) => {
	return httpClient.post(`/Member/AddEmail/${personId}`,formdata);
};

export const addNewCompanyEmail = (addressId: string, payloadBody) => {
	return httpClient.post(`/Company/Address/Email/Save/${addressId}`,payloadBody);
};

export const addNewPhone = (formdata:any,personId:any) => {
	return httpClient.post(`/Member/AddPhoneNumber/${personId}`,formdata);
};

export const saveBusinessTaxInfo = (payload: BusinessTaxInfoModel) => {
	return httpClient.post('/Company/BusinessTaxInfo/Save', payload);
};

export const deleteBusinessTaxInfoDocument = (payload: IDeleteDocumentValue) => {
	return httpClient.delete('/Company/BusinessTaxInfo/DocumentDelete', payload);
};

export const saveSocialMedia = (payload: ISocialMediaAddModel) => {
	return httpClient.post('/Company/SocialMedia/Save', payload);
};

export const getSocialMediaListByCompany = (id: any) => {
	return httpClient.get(`/Company/SocialMedias/${id}`);
};

export const deleteSocialMedia = (payload:any) => {
	return httpClient.delete('/Company/SocialMedia/Delete', payload);
};

export const getAllSignatories = (filterModel: IFilterModel, companyId: string) => {
	return httpClient.post<IPagedResultModel<ISignatoryRecord>>(`/Company/Signatories/${companyId}`, filterModel);
};

export const getBySignatoryVendor = (companyId: string, filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ISignatoryVendorRecord>>(`/Company/SignatoryVendor/GetBy/${companyId}`, filterModel);
};

export const getAllSignatoryVendorIds = (companyId: string) => {
	return httpClient.get<string[]>(`Company/SignatoryVendor/GetAllIds/${companyId}`);
};

export const saveSignatoryVendor = (payload: ICompanySignatoryMutate) => {
	return httpClient.post('/Company/SignatoryVendor/Save', payload);
};

export const saveSignatory = (payload: any) => {
	return httpClient.post<IOperationResultModel>('/Company/Signatory/Save', payload);
};

export const deleteSignatory = (payload:any) => {
	return httpClient.delete('/Company/Signatory/Delete', payload);
};

export const checkSignatoryVendorDependencies = (companyId:string) => {
	return httpClient.get(`/Company/CheckSignatoryVendorDependencies/${companyId}`);
};

export const deleteSignatoryVendor = (body: Omit<ICompanySignatoryMutate, 'isFirstParty'|'signatoryIds'>) => {
	return httpClient.deleteV2('/Company/SignatoryVendor/Delete', { body, headers: {} });
};

export const getCompaniesBySearch = (companyName:string) => {
	return httpClient.get(`/Company/CompaniesBySearch/${companyName}`);
};

export const getBusinessTaxInfo = (companyId: any) => {
	return httpClient.get(`/Company/BusinessTaxInfo/${companyId}`);
};

export const getClientAdvertisersByCompany = (filterModel: IFilterModel, companyId:any) => {
	return httpClient.post<IPagedResultModel<IClientAdvertiser>>(`/Company/ClientAdvertisers/${companyId}`, filterModel);
};

export const getLinkedAdvertisersByCompany = (filterModel: IFilterModel, companyId:string) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>(`/Company/LinkedClientAdvertisers/${companyId}`, filterModel);
};

export const saveClientAdvertiser = (payload: IClientAdvertiserModel) => {
	return httpClient.post('/Company/ClientAdvertiser/Save', payload);
};

export const deleteClientAdvertiser = (payload:any) => {
	return httpClient.delete('/Company/ClientAdvertiser/Delete', payload);
};

export const createCompanyAddressPhoneNumber = (payload: any) => {
	return httpClient.post('/Company/Address/PhoneNumber/Save', payload);
};

export const getCompanyExclusivities = (companyIds: string[]) => {
	return httpClient.post<string[]>('Company/Product/GetExclusivities', companyIds);
};

export const getBrandsByCompany = (filterModel: IFilterModel, companyId: string) => {
	return httpClient.post<IPagedResultModel<BrandModel>>(`/Company/Brand/${companyId}`, filterModel);
};

export const getPayrollVendorsOfCompany = (filters: IFilterModel, companyIds: string[]) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('Company/PayrollVendorsByCompany', { filters, companyIds });
};

/**
 * @deprecated This function is deprecated and will be removed in future releases.
 */
export const getLocationCompanyForAsset = (filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('Company/LocationForAsset', filters);
};

/**
 * @deprecated This function is deprecated and will be removed in future releases.
 */
export const getProducerCompanyForContract = (filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('Company/GetProducerCompanyForContract', filters);
};

export const updateCompanyNotes = (payload: any) => {	
	return httpClient.post('Company/Note/Update', payload);
};

export const deleteCompanyNotes = (payload: any) => {
	return httpClient.delete('Company/Note/Delete',payload);
};

export const getCompanyBrandDivison = (companyId: any) => {
	return httpClient.get(`Company/BrandsDivisons/${companyId}`);
};

export const getRelatedCompanies = (filterModel: any) => {
	return httpClient.post('/Company/Relationships/Companies', filterModel);
};

export const getRelatedPersons = (filterModel: any) => {
	return httpClient.post('/Company/Relationships/Persons', filterModel);
};

export const getRelatedAssets = (filterModel: any) => {
	return httpClient.post('/Company/Relationships/Assets', filterModel);
};

export const GetRelatedElements = (filterModel: any) => {
	return httpClient.post('/Company/Relationships/Elements', filterModel);
};

export const saveAdAgencyAdvertiser = (payload: any) => {
	return httpClient.post('/Company/AdAgencyAdvertiser/Save', payload);
};

export const getAdAgencyAdvertiser = (filters: IFilterModel, id: string) => {
	return httpClient.post<IPagedResultModel<any>>(`/Company/AdAgencyAdvertiser/Get/${id}`, filters);
};

export const getAdAgencyAdvertisersByIds = (ids: string[]) => {
	return httpClient.post<any>('/Company/GetAdAgencyAdvertisersByIds', ids);
};

export const getCompanyPrimaryEmailIdsForContract = (payload) => {
	return httpClient.post<any>('/Company/Emails/GetByCompanyIds', payload);
};

export const GetCompanyIds = (companyName: string) => {
	return httpClient.get<any>(`/Company/GetIdOfCompany/${companyName}`);
};

export const getAllCompaniesOfStaff = (filters: IFilterModel, personId: string) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>(`/Company/Staff/GetAllByPerson/${personId}`, filters);
};


export const getCombinedSignatoryPrimaryStaffForCompany = async(
	filterModel: FilterModel,
	companyId: string,
): Promise<AxiosResponse<IPagedResultModel<StaffViewModel>, any>> => {

	if(!filterModel?.filters){
		filterModel.filters = [];
	}
	const pFilters = JSON.parse(JSON.stringify(filterModel));
	const nFilters = JSON.parse(JSON.stringify(filterModel));

	pFilters.filters = [...pFilters.filters, new FilterOption('true', 'StaffsDetails.HasStaff.IsPrimaryStaff')];
	nFilters.filters = [...nFilters.filters, new FilterOption('false', 'StaffsDetails.HasStaff.IsPrimaryStaff')];

	const [pSignatory, nSignatory] = await Promise.all([
		getCompanyStaffListByCompany(pFilters, companyId),
		getCompanyStaffListByCompany(nFilters, companyId),
	]);

	const totalRecords =
		(pSignatory?.data?.totalRecords ?? 0) +
		(nSignatory?.data?.totalRecords ?? 0);
	const records = [
		...(pSignatory?.data?.records ?? []),
		...(nSignatory?.data?.records ?? []),
	];

	return {
		...pSignatory,
		...nSignatory,
		data: { totalRecords, records },
	};
};

// #region Duplicated endpoint from another controller for permission handling.
export const getCompanyStaffListByCompany = (filterModel: IFilterModel,id:any) => {
	return httpClient.post<IPagedResultModel<StaffViewModel>>(`/Company/Staff/GetAll/${id}`, filterModel);
};

export const getCompanyAdvertID = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<AdIDModel>(`/Company/GetAllCompanyADID/${id}`, filterModel);
};

export const getAllCompanyTransferOfRight = (filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IAssetTransferOfRightRecord>>('/Company/CompanyTransferOfRight/GetBy', filters);
};

export const savePersonAsStaffFromCompany = (payload: any) => {
	return httpClient.post('/Company/People/AddStaff', payload);
};
// #endregion