import { IRoleTypeForm } from '@/modules/admin/components/tabs/adminAction/roleTypeForm';
import { IUserAuthInfo } from '@/store/auth/types';
import { httpClient } from './httpClient';
import { IChangeUserPassword, ICreateUser, ILoginUser, IUserMutateModel, IUserRecord } from './models/user';
import { IFilterModel, IPagedResultModel } from '@/common/types/filterModel';
import { IOperationResultModel } from './models/asset';

export const createUser = (payload: ICreateUser, pId) => {
	return httpClient.post<IUserAuthInfo>(`/User/AddUser/${pId}`, payload);
};

export const userLogin = (payload: ILoginUser) => {
	return httpClient.post<IUserAuthInfo>('/User/Login', payload);
};

export const userChangePassword = (payload: IChangeUserPassword) => {
	return httpClient.post('/User/ChangePassWord', payload);
};

export const getUserByName = (userName: string) => {
	return httpClient.post(`/User/GetUser/${userName}`);
};

export const getUserById = (id: string) => {
	return httpClient.post(`/User/GetUserById/${id}`);
};

export const getUserProfileDetailsById = (id: string) => {
	return httpClient.get<IUserRecord>(`/User/GetUserProfileDetailsById/${id}`);
};

export const getUserByResetToken = (token: string) => {
	return httpClient.post(`/User/GetUserByResetToken/${token}`);
};

export const sendResetPasswordNotification = (payload: any) => {
	return httpClient.post('/User/SendResetPasswordNotification', payload);
};

export const resetPassword = (payload: any) => {
	return httpClient.post('/User/ResetPassword', payload);
};

export const sendOtp = (userId: string) => {
	return httpClient.post(`/User/ResetPassword/SendOtp/${userId}`);
};

export const validateOtp = (userId: string, otp: string) => {
	return httpClient.post(`/User/ResetPassword/ValidateOtp/${userId}`, otp);
};

export const mutateRoleType = (payload: IRoleTypeForm) => {
	return httpClient.post<IOperationResultModel>('/User/RoleType/Mutate', payload);
};

export const getAllRoleType = (filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IRoleTypeForm>>('/User/RoleType/GetBy', filters);
};

export const getAllRoleTypeForBasicDetail = (filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IRoleTypeForm>>('/User/RoleType/GetByForBasicDetail', filters);
};

export const deleteRoleType = (roleTypeId: string) => {
	return httpClient.delete<IOperationResultModel>(`/User/RoleType/Delete/${roleTypeId}`);
};

export const mutateUserDetail = (userMutationPayload: IUserMutateModel) => {
	return httpClient.post<IOperationResultModel>('/User/Mutate', userMutationPayload);
};

export const getAllUserDetails = (filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IUserRecord>>('/User/GetBy', filters);
};

export const deleteUserDetail = (userId: string) => {
	return httpClient.delete<IOperationResultModel>(`/User/Delete/${userId}`);
};

export const getUserPermission = () => {
	return httpClient.get<IUserRecord>('/User/GetPermission');
};

export const toggleBlockUser = (payload: { userId: string, isBlocked: boolean }) => {
	return httpClient.post(`/User/${payload.userId}/ToggleBlockUser/${payload.isBlocked}`);
};
